<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="warningPercentageModel"
    label-width="130px"
    >
    <el-form-item label="标段：" prop="tenderGuid">
      <el-select v-model="warningPercentageModel.tenderGuid" clearable>
        <el-option
          v-for="tender in tenders"
          :key="tender.tenderGuid"
          :label="tender.tenderName"
          :value="tender.tenderGuid"
          >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="预警类型：" prop="warningType">
      <el-select v-model="warningPercentageModel.warningType" clearable>
        <el-option
          v-for="warningType in warningTypes"
          :key="warningType.dictionaryGuid"
          :label="warningType.dictionaryValue"
          :value="warningType.dictionaryValue"
          >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="配置名称1：" prop="configName">
      <el-input
        type="text"
        v-model="warningPercentageModel.configName">
      </el-input>
    </el-form-item>
    <el-form-item label="配置名称2：" prop="configName1">
      <el-input
        type="text"
        v-model="warningPercentageModel.configName1">
      </el-input>
    </el-form-item>
    <el-form-item label="清单配置名称：" prop="meterageListConfigName">
      <el-input type="text" v-model="warningPercentageModel.meterageListConfigName">
      </el-input>
    </el-form-item>
    <el-form-item label="合同配置名称：" prop="contractAmountConfigName">
      <el-input type="text" v-model="warningPercentageModel.contractAmountConfigName">
      </el-input>
    </el-form-item>
    <el-form-item label="支付配置名称：" prop="totalPaymentConfigName">
      <el-input type="text" v-model="warningPercentageModel.totalPaymentConfigName">
      </el-input>
    </el-form-item>
    <el-form-item label="预警比例（%）">
      <el-input-number
        v-model="warningPercentageModel.meteragePercentage"
        :step="0.01"
        :min="0"
        :max="100"
        :precision="2"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="已预警">
      <el-switch
        v-model.number="warningPercentageModel.isWarning"
        active-color="#13ce66"
        inactive-color="#ff4949"
        active-text="是"
        inactive-text="否"
        :active-value="1"
        :inactive-value="0"
        >
      </el-switch>
    </el-form-item>
  </el-form>
</template>

<script>
import dictionaryService from '@/services/dictionaryService'
import WarningPercentageModel from '@/model/WarningPercentageModel'
import tenderService from '@/services/tenderService'
import auth from '@/common/auth'
import PageInfo from '@/support/PageInfo'
import config from '@/config'

export default {
  name: 'WarningPercentageForm',
  props: {
    warningPercentageModel: {
      type: WarningPercentageModel,
      default () {
        return new WarningPercentageModel()
      }
    }
  },
  data () {
    return {
      tenders: [],
      warningTypes: [],
      rules: {
        tenderGuid: [
          {
            required: true,
            message: '请选择标段',
            trigger: 'blur'
          }
        ],
        warningType: [
          {
            required: true,
            message: '请输入预警类型',
            trigger: 'blur'
          }
        ],
        configName: [
          {
            required: true,
            message: '请输入配置名称1',
            trigger: 'blur'
          }
        ],
        configName1: [
          {
            required: true,
            message: '请输入配置名称2',
            trigger: 'blur'
          }
        ],
        meterageListConfigName: [
          {
            required: true,
            message: '请输入清单配置名称',
            trigger: 'blur'
          }
        ],
        contractAmountConfigName: [
          {
            required: true,
            message: '请输入合同配置名称',
            trigger: 'blur'
          }
        ],
        totalPaymentConfigName: [
          {
            required: true,
            message: '请输入支付配置名称',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      if (this.warningPercentageModel.tenderGuid) {
        this.warningPercentageModel.tenderName = this.tenders.find(item => item.tenderGuid === this.warningPercentageModel.tenderGuid).tenderName
      }
      return this.$refs.elForm.validate
    },
    getTenders () {
      tenderService.list(new PageInfo({ pageIndex: 1, pageSize: config.bigPageSize, fieldOrder: 'sordId asc' }), { projectGuid: auth.getUserInfo().projectGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.tenders = res.data.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getWarningTypes () {
      dictionaryService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'sortId' }), { dictionaryKey: 'warningType' })
        .then(res => {
          if (res.data.code === 1) {
            this.warningTypes = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    }
  },
  created () {
    this.getTenders()
    this.getWarningTypes()
  }
}
</script>
