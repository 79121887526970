import utility from '@/common/utility'

export default class UserModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.warningGuid
      this.warningGuid = props.warningGuid
      this.tenderGuid = props.tenderGuid
      this.tenderName = props.tenderName
      this.meteragePercentage = props.meteragePercentage
      this.isWarning = props.isWarning
      this.warningType = props.warningType
      this.configName = props.configName
      this.meterageListConfigName = props.meterageListConfigName
      this.configName1 = props.configName1
      this.contractAmountConfigName = props.contractAmountConfigName
      this.totalPaymentConfigName = props.totalPaymentConfigName
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.warningGuid) this.warningGuid = ''
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.tenderName) this.tenderName = ''
    if (!this.meteragePercentage) this.meteragePercentage = 0.00
    if (!this.isWarning) this.isWarning = ''
    if (!this.warningType) this.warningType = ''
    if (!this.configName) this.configName = ''
    if (!this.meterageListConfigName) this.meterageListConfigName = ''
    if (!this.configName1) this.configName1 = ''
    if (!this.contractAmountConfigName) this.contractAmountConfigName = ''
    if (!this.totalPaymentConfigName) this.totalPaymentConfigName = ''
  }

  generatePrimaryKey () {
    this.warningGuid = utility.getUuid()
    this.resourceId = this.warningGuid
  }
}
