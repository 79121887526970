<template>
  <div class="warningPercentage-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button type="primary" @click="handleAddClick">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      :data="warningPercentageList"
      border
      header-cell-class-name="bg-info text-light"
      >
      <el-table-column type="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="tenderName" header-align="center" align="center" label="标段"></el-table-column>
      <el-table-column prop="warningType" header-align="center" align="center" label="预警类型"></el-table-column>
      <el-table-column prop="configName" header-align="center" align="center" label="配置名称1"></el-table-column>
      <el-table-column prop="configName1" header-align="center" align="center" label="配置名称2"></el-table-column>
      <el-table-column prop="meterageListConfigName" header-align="center" align="center" label="清单配置名称"></el-table-column>
      <el-table-column prop="contractAmountConfigName" header-align="center" align="center" label="合同配置名称"></el-table-column>
      <el-table-column prop="totalPaymentConfigName" header-align="center" align="center" label="支付配置名称"></el-table-column>
      <el-table-column prop="meteragePercentage" header-align="center" align="center" label="预警比例(%)"></el-table-column>
      <el-table-column prop="isWarning" :formatter="boolFormatter" header-align="center" align="center" label="已预警"></el-table-column>
      <el-table-column  header-align="center" align="center" width="90" label="操作">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <el-tooltip class="item" effect="light" content="编辑" :open-delay="500" placement="top">
            <i @click="handleEditClick(scope.row)" class="text-info far fa-edit mr-2 operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <my-dialog ref="formDialog"
      :title="formDialogTitle"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleOkClick">
      <warningPercentage-form
        ref="warningPercentageForm"
        :warningPercentageModel="warningPercentageModel">
      </warningPercentage-form>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import WarningPercentageForm from '@/views/WarningPercentageManage/WarningPercentageForm'
import WarningPercentageModel from '@/model/WarningPercentageModel'
import warningPercentageService from '@/services/warningPercentageService'
import companyService from '@/services/companyService'
import PageOperation from '@/mixins/PageOperation'
import auth from '@/common/auth'

export default {
  name: 'WarningPercentageIndex',
  mixins: [
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar,
    WarningPercentageForm
  },
  data () {
    return {
      total: 0,
      warningPercentageList: [],
      warningPercentageModel: new WarningPercentageModel(),
      companys: [],
      formDialogTitle: '',
      searchModel: {
        warningPercentageName: '',
        warningPercentageAlias: '',
        ownerCode: '',
        companyGuid: '',
        projectGuid: auth.getUserInfo().projectGuid
      }
    }
  },
  methods: {
    boolFormatter (row, column) {
      if (!row.empty) {
        if (row[column.property]) {
          return '是'
        } else {
          return '否'
        }
      }
      return ''
    },
    handleSearchClick () {
      this._getWarningPercentageList()
    },
    handleAddClick () {
      this.formDialogTitle = '添加项目信息'
      this.warningPercentageModel = new WarningPercentageModel()
      this.$refs.formDialog.open()
    },
    handleEditClick (row) {
      this.formDialogTitle = '编辑项目信息'
      this.warningPercentageModel = new WarningPercentageModel(row)
      this.$refs.formDialog.open()
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _warningPercentageModel = new WarningPercentageModel(row)
          warningPercentageService.delete(_warningPercentageModel.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getWarningPercentageList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.warningPercentageForm.validate()(valid => {
        if (valid) {
          if (!this.warningPercentageModel.resourceId) {
            this._save()
          } else {
            this._edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    _getWarningPercentageList () {
      warningPercentageService.list(null, this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.total = res.data.pageCount
            this.warningPercentageList = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _save () {
      warningPercentageService.add(this.warningPercentageModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getWarningPercentageList()
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _edit () {
      warningPercentageService.edit(this.warningPercentageModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getWarningPercentageList()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _getCompanys () {
      companyService.list()
        .then(res => {
          if (res.data.code === 1) {
            this.companys = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    }
  },
  created () {
    this._getWarningPercentageList()
    this.refreshMethod = this._getWarningPercentageList
    this._getCompanys()
  }
}
</script>
