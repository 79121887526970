import BaseService from '@/services/BaseService'

let resourceName = 'warning'

class WarningPercentageService extends BaseService {
}

const warningPercentageService = new WarningPercentageService(resourceName)

export default warningPercentageService
